@charset "utf-8";

@use 'sass:color';

:root {
    $color-dark: #232871;
    $color-light: #bfdff3;

    // PrimeVue theme
    --text-color: #{$color-dark};
    --primary-color: #{$color-dark};

    // FWF
    --color-dark: #{$color-dark};
    --color-light: #{$color-light};
    --secondary-color: #{$color-light};
    --tertiary-color: #{color.adjust($color-light, $alpha: -0.7)};
    --secondary-color-text: #{$color-dark};
    --tertiary-color-text: #{$color-dark};
    --primary-color-translucent: #{color.adjust($color-dark, $alpha: -0.05)};
    --secondary-color-transparent: #{color.adjust($color-light, $alpha: -0.15)};
}
