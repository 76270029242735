@charset "utf-8";

@import 'theme';
@import 'fwf/theme';

.logo {
    &.language-de {
        mask-image: url('../logos/logo_fwf_de.svg');
    }

    &.language-en {
        mask-image: url('../logos/logo_fwf_en.svg');
    }
}
