:root {
    color-scheme: light;

    // PrimeVue theme
    --font-family: Aeonik, 'Helvetica Neue', Helvetica, 'Arial Nova', 'Noto Sans', 'Nimbus Sans', 'Liberation Sans', 'DejaVu Sans', Arial, sans-serif;
    --primary-color-text: #bfdff3; // text colour on background in primary colour

    // Shoelace
    $input-border-radius: 30px;
    --sl-input-color: var(--primary-color);
    --sl-input-font-size-medium: var(--font-size-medium);
    --sl-input-border-color: var(--primary-color);
    --sl-input-color-hover: var(--primary-color);
    --sl-input-color-focus: var(--primary-color);
    --sl-input-icon-color: var(--primary-color);
    --sl-border-radius-medium: var($input-border-radius);
    --sl-input-border-radius-medium: var($input-border-radius);
    --sl-border-radius-pill: var($input-border-radius);

    // FWF / Scilog
    --error-color: #f00;
}
